@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.inputfile {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  position: relative;
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  border-radius: 50%;
  overflow: hidden;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  line-height: 50px; /* Adjust line height to center content */
  cursor: pointer;
}

.upload-icon {
  font-size: 30px; /* Adjust icon size as needed */
}

.under
{
  border-color: #F09819;
}


::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #F66714; /* Change scrollbar color */
  border-radius: 20px;
}




.bebas {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}


.urbanist{
  font-family: "Urbanist", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}