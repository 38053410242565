.container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: transparent;
    border-radius: 10px;
    color: white;
    font-family: Arial, sans-serif;
  }
  
  .header {
    text-align: center;
    color: #e64e17;
    margin-bottom: 30px;
  }
  
  .profile-info {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .profile-info img {
    border-radius: 50%;
    max-width: 150px;
    justify-self: center;
  }
  
  .profile-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .profile-details p {
    margin: 5px 0;
  }
  
  .projects {
    background-color: #282c34;
    padding: 20px;
    border-radius: 8px;
  }
  
  .projects h2 {
    color: #61dafb;
  }
  
  .project-list {
    list-style-type: none;
    padding: 0;
  }
  
  .project-item {
    margin: 10px 0;
  }
  
  .project-item a {
    color: #61dafb;
    text-decoration: none;
    font-size: 18px;
  }
  
  .project-item a:hover {
    text-decoration: underline;
  }
  