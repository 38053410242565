@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

* {
    box-sizing: border-box;
}

body {
    padding: 0%;
    margin: 0%;
    font-family: 'Urbanist', sans-serif;
    background-color: #F3F3F3;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

img {
    max-width: 100%;
}

.font-urbanist {
    font-family: 'Urbanist', sans-serif;
    font-optical-sizing: auto;
  }
  
.bebas-neue-regular {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

main {
    padding: 10px;
    margin: 0 auto;
}

header a.logo,
header nav,
div.post,
div.post div.texts a,
form.login,
form.register,
input,
.post-page,
.signup-button,
.signin-button {
    color: initial;
    background-color: initial;
    border: initial;
    
}
header{
    display:flex;
    justify-content:space-between;
    margin-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
    padding: 0.5rem 2rem;
    padding-left: 2rem;
    align-items: center;
    box-shadow: 0px 2.98256px 17.4564px rgba(0, 0, 0, 0.1);
    background-color: rgba(255,255,255,0.1);
    border-radius: 10PX;
    border: 2px solid #FB360F;
    @media screen and (max-width: 700px) {
        flex-direction: column;
        gap: 10px;
    }
}

.header-title{
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 10px;

}

.header-title-3
{
    background-color: transparent;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
}

.header-title-2{
    background-color: transparent;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 5px;

}

header a{
    text-decoration:none;
    color: inherit;
}
header a.logo{
    font-weight: bold;
    font-size: 1.5rem;
    
}
header nav{
    display:flex;
    gap: 24px;
    background-color: transparent;
}

div.post{
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 30px;
}
@media screen and (min-width: 700px) {
    div.post{
        grid-template-columns: .9fr 1.1fr;
    }
}

div.post div.texts h2{
    margin:0;
    font-size: 2rem;
}
div.post div.texts a{
    text-decoration:none;
    color: inherit;
}
div.post p.info{
    margin:24px 0;
    color: #888;
    font-size:1rem;
    font-weight: bold;
    display: flex;
    gap: 10px;
}
div.post p.info a.author{
    color:#333;
}
div.post p.summary{
    margin:10px 0;
    line-height: 1.8rem;
}

input{
    display: block;
    margin-bottom: 5px;
    width: 90%;
    padding: 5px 7px;
}

.login-input {
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: #F3F3F3;
    border-radius: 0px;
  }
button{
    font-family: "Urbanist", sans-serif;
    font-optical-sizing: auto;
    cursor: pointer;
    width: auto;
    display: block;
    border: 0px;
    color: #fff;
    border-radius: 10px;
}
form.login h1, form.register h1{
    text-align: center;
}

div.post-page div.image{
    max-height:300px;
    display: flex;
    overflow:hidden;
}
div.post-page div.image img{
    object-fit: cover;
    object-position: center center;
    width: 100%;
}
div.post-page a{
    color:#333;
    text-decoration: underline;
}
div.post-page h1{
    text-align: center;
    margin: 10px 0 5px;
}
div.post-page time{
    text-align: center;
    display: block;
    font-size:1rem;
    color:#aaa;
    margin: 10px 0;
}
div.post-page div.author{
    text-align: center;
    margin-bottom: 20px;
    font-size: .7rem;
    font-weight: bold;
}
div.content p{
    line-height: 1.7rem;
    margin: 30px 0;
}
div.content li{
    margin-bottom: 10px;
}
div.edit-row{
    text-align: center;
    margin-bottom: 20px;
}
div.post-page a.edit-btn{
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: #fff;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    text-decoration: none;
}
a svg{
    height:20px;
}

.logo-image {
    margin-right: px; /* Adjust the margin as needed */
}

.logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: rgb(237, 133, 88);
    transition: 0.3s;
    transform: scale(1.3);
    margin-right: auto;
}

.logo:hover {
    color: rgb(255, 255, 255);
    transition: 0.3s;
    transform: scale(1.5);
}

.logo span {
    color: rgb(255, 255, 255);
    transition: 0.3s;
}

.logo span:hover {
    color: rgb(237, 133, 88);
    transition: 0.3s;
    transform: scale(1.2);
}

.nav-icon {
    background-color: transparent;
    display: flex;
    align-items: center;
    color: rgb(255, 255, 255);
    transition: 0.3s;
    padding: 0.5rem 1rem;
    font-family: 'Bebas Neue', sans-serif;
}

.nav-icon:hover {
    background-color: transparent;
    color:rgb(237, 133, 88);
    transform: scale(1.2);
    transition: 0.3s;
}

.nav-icon-image {
    background-color: transparent;
    width: 20px; 
    margin-right: 5px; 
  }
  
.post {
    transition: 0.3s;
    padding-top: 6rem;
}

.post:hover {
    transform: scale(1.1);
    cursor: pointer;
    transition: 0.3s;
}

.heading2{
    color: rgb(154, 206, 76);
    transition: 0.3s;
}

.heading2:hover {
    color: rgb(101, 196, 234);
    transition: 0.3s;
}

.edit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.create-post-form {
    padding-top: 3rem;
}

input {
    margin-top: 1rem;
    border: 3px rgb(144, 144, 144) solid;
}

.editor-border {
    border: 3px rgb(144, 144, 144) solid;
}

.post-page {
    padding-top: 5rem;
}

.signup-button {
    font-family: "Urbanist", sans-serif;
    font-optical-sizing: auto;
    background-color: rgb(255, 81, 47);
    color: #fff; 
    padding: 0.5rem 1rem; 
    border-radius: 10px;
    text-decoration: none;
  }
  
  .signin-button {
    font-family: "Urbanist", sans-serif;
    font-optical-sizing: auto;
    border: 2px solid rgb(255, 81, 47);
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    text-decoration: none;
    background-color: transparent; 
  }
  
  /* Add this to your CSS file */
  .start-project-button {
    font-family: "Urbanist", sans-serif;
    font-optical-sizing: auto;
    border: 2px solid rgb(255, 255, 255);
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    text-decoration: none;
    background-color: transparent; 
  }
  



  .App {
    text-align: center;
  }
  
  .App-header {
    background-color: #282c34;
    padding: 20px;
    color: white;
  }
  
  .main {
    padding: 20px;
  }
  
  .canvas-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .canvas-container canvas {
    border: 1px solid #000;
    margin-right: 20px;
  }
  
  .word {
    margin-bottom: 10px;
    cursor: pointer;
  }

 

.logout-button
{
        font-family: "Urbanist", sans-serif;
        font-optical-sizing: auto;
        border: 2px solid rgb(255, 81, 47);
        color: #fff;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        text-decoration: none;
        background-color: transparent; 
}

#canvas-page .canvas-container-wrapper {
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    backdrop-filter: blur(10px);
  }
  
#canvas-page .canvas-container {
    overflow: auto;
    margin-bottom: 20px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    backdrop-filter: blur(10px);
  }
  
#canvas-page .word-container, #canvas-page .input-container, #canvas-page .glass-effect {
    background-color: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px;
    backdrop-filter: blur(10px);
  }
  
#canvas-page .add-button, #canvas-page .delete-button, #canvas-page .download-button {
    background: linear-gradient(to bottom right, #FB360F, #F28A18);
    border: none;
    border-radius: 10px;
    color: white;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
#canvas-page .add-button:hover, #canvas-page .delete-button:hover, #canvas-page .download-button:hover {
    background-color: #F28A18;
  }
  
#canvas-page .glass-effect {
    background-color: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 10px;
    backdrop-filter: blur(10px);
  }
  
#canvas-page .glass-effect:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
.menu-popup {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
    background: rgba(255, 255, 255); 
    border: 2px solid black;
    border-radius: 10px;
    width:100%;
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  
  .popup-content {
    width: 100%;
  }
  
  .popup-content nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 20px;
  }


  .projectNamediv{
    border: 2px solid white;
    border-radius: 0.75rem;
  }

  .standardrgbcolor{
    color: rgb(255, 81, 47);
  }

  .center-loading {
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    width: 100%;
  }
  
  .loading-popup {
    position: fixed; /* Fixed position to overlay on top */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* Ensure it is on top of other content */
  }
  
  .animate-spin {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  